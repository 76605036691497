import React from 'react'
import Head from 'next/head'
import Home from '../../src/pages/website/Home/Home'
import CommonHead from '../_head'

const HomePage = () => (
  <>
    <Head>
      <title>Best Entrepreneur Learning & Mentoring Course - {new Date().getFullYear()}</title>
      <CommonHead description="Learn the do’s and don’ts of becoming a successful entrepreneur with a full 12-month mentorship program and a chance to pitch your business to investors. This is the only Entrepreneurship course that combines essential startup knowledge with world-class mentorship." />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,700;1,900&display=swap"
        rel="stylesheet"
      />
    </Head>

    <Home />
  </>
)

export default HomePage
