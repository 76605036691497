import styled from 'styled-components'
import { BackgroundPattern } from '@components/images'
import { PageContent, View } from '@components/layout'
import chunk from 'lodash/chunk'
import { absoluteFill } from 'theme/styles'
import { homeWidths } from '../../constants'
import { StatItem } from './StatItem'
import { statsContent } from './content'

const Container = styled(PageContent)`
  padding: 0;
  margin-bottom: ${(props) => props.theme.spacing.md}px;
`

const Content = styled(View)`
  position: relative;
  width: 100%;
  align-items: center;
`

const StatsList = styled(View)`
  position: relative;
  max-width: ${homeWidths.lg}px;
  padding: ${(props) => props.theme.spacing.md}px;
`

const StatsGroup = styled(View)`
  flex-direction: row;
  justify-content: center;
`

const Background = styled(BackgroundPattern)`
  ${absoluteFill};
  top: 80px;
  bottom: ${(props) => -props.theme.spacing.md}px;
  background: ${(props) => props.theme.colors.primary};
`

export const StatsGrid = () => {
  return (
    <Container fluid>
      <Content>
        <Background patternOpacity={0.4} patternScale={2} />
        <StatsList>
          {chunk(statsContent, 3).map((statsGroup) => (
            <StatsGroup key={statsGroup[0].text.slice(0, 10)}>
              {statsGroup.map(StatItem)}
            </StatsGroup>
          ))}
        </StatsList>
      </Content>
    </Container>
  )
}
