import styled from 'styled-components'
import { Testimonials } from '@components'
import { H2 } from '@components/typography'
import { media } from 'styled-bootstrap-grid'
import { breakpoints } from 'theme/breakpoints'

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: false,
  responsive: [
    {
      // breakpoint means 'everything up to..'
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: breakpoints.md,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: breakpoints.sm,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const StyledTestiominals = styled(Testimonials)`
  ${media.md`
    max-width: 650px;
  `}
  ${media.lg`
    max-width: 1300px;
  `}
`

const Heading = styled(H2)`
  padding: 0 ${(props) => props.theme.spacing.xlg}px;
  text-align: center;
  font-weight: bold;
  align-self: center;
`

export const HomeTestimonials = () => {
  return (
    <>
      <Heading>What Entrepreneurs Have to Say</Heading>
      <StyledTestiominals fluid hideCourseButton variant="b" sliderSettings={sliderSettings} />
    </>
  )
}
