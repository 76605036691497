import styled from 'styled-components'
import { BackgroundPattern } from '@components/images'
import { PageContent, View } from '@components/layout'
import { H2, P } from '@components/typography'
import { WistiaPlayer } from '@components/video'
import { media } from 'styled-bootstrap-grid'
import { shadows } from 'theme/styles'
import { CenteredContent } from '../components'

const Container = styled(PageContent)`
  position: relative;
  align-items: center;
  padding: ${(props) => props.theme.spacing.md}px;
`

const Background = styled(BackgroundPattern)`
  background-color: ${(props) => props.theme.colors.grayLighter};
`

const TextWrapper = styled(View)`
  flex: 1;
  padding: ${(props) => props.theme.spacing.md}px;
`

const PlayerAndCaptionWrapper = styled(View)`
  flex: 1;
  align-self: center;
  width: 100%;
`

const PlayerWrapper = styled(View)`
  ${shadows.subtle}
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
  overflow: hidden;
  border-radius: 20px;
  // fixes "overflow: hidden" not working on Safari
  transform: translateZ(0);
`

const Title = styled(H2)`
  font-weight: bold;
  margin-block-start: 0;
`

const TitleComponent = (props) => <Title {...props}>Jax Jones Discusses his Mentoring Experience with Martin</Title>

const SmallScreenTitle = styled(TitleComponent)`
  text-align: center;

  ${media.md`
    display: none;
  `}
`

const LargeScreenTitle = styled(TitleComponent)`
  display: none;

  ${media.md`
    display: block;
  `}
`

const VideoCaption = styled(P)`
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  text-align: center;
`

export const Mentoring = () => {
  return (
    <Container fluid>
      <Background patternOpacity={0.05} patternScale={2} grayscalePercent={100} />

      <CenteredContent>
        <SmallScreenTitle />

        <PlayerAndCaptionWrapper>
          <PlayerWrapper>
            <WistiaPlayer
              playerId="home-mentoring-video"
              url="https://entrepreneurseminar-es.wistia.com/medias/zo4nnlqoz5"
            />
          </PlayerWrapper>
          <VideoCaption>Jax Jones is an internationally renowned recording artist & DJ</VideoCaption>
        </PlayerAndCaptionWrapper>

        <TextWrapper>
          <LargeScreenTitle />
          <P>
            68% of mentored businesses make a <b>profit in year one</b>, and mentored businesses are{' '}
            <b>twice as likely</b> to make it past year five than businesses without a mentor.
            <br />
            <br />
            Mentors provide a different perspective on your situation and spot opportunities that you may miss. Running
            a business means that your head is often ‘in the sand’ caught up with the day-to-day running of your
            start-up. Being able to get a fresh viewpoint is <b>crucial</b>.
            <br />
            <br />
            At Entrepreneur Seminar, you can access <b>mentorship direct from leading entrepreneur</b> Martin Warner on
            the <b>Idea to Exit Masterclass and Starter Programs</b>. You get to have Martin’s world-class
            entrepreneurial mind <b>helping you</b> start and grow your business.
          </P>
        </TextWrapper>
      </CenteredContent>
    </Container>
  )
}
