export const quotesTickerContent = [
  `It's the best program I've come across in many years of seeking the right mentor and program`,
  `Martin’s unique insights empowered me to view my music through a business lens`,
  `Martin has enabled me to land an extra 6 figures to my business`,
  `Martin's program was inspirational and has pointed me in the right direction.`,
  `I've more than doubled my revenue since I started the Entrepreneur Seminar program!`,
  `Every bit is as good as Sam Altman's 'How to Start a Start-Up class' taught at Standford University`,
  `Martin's direct mentoring has been exceptionally valuable`,
  `Entrepreneur Seminar is a must for any aspiring startup founder, investor, and/or advisor.`,
]
