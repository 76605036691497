import React, { useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import styled from 'styled-components'
import { Icon } from '@components/images'
import { View } from '@components/layout'
import { H3 } from '@components/typography'
import { media } from 'styled-bootstrap-grid'
import { quotesTickerContent } from './quotesTickerContent'

// Fixing the height so the height of the box is not dependent
// on the text, which can be varying numbers of lines long
const HEIGHT = '110px'

const CHANGE_INTERVAL = 5000
const ANIMATION_DURATION = 500

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0, top: -300 },
  exited: { opacity: 0 },
}

const Container = styled(View)`
  background: ${(props) => props.theme.colors.opacity(0.6)};
  width: 100%;
  height: ${HEIGHT};
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled(View)`
  position: absolute;
  height: ${HEIGHT};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: row;
`

const Quote = styled(H3)`
  margin: 5px 30px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  position: relative;
  transition: all ${ANIMATION_DURATION}ms ease-in-out;
  top: 0;
  display: flex;
  flex-direction: row;
  font-weight: 600;

  ${media.sm`
    max-width: 80%;
  `}

  ${media.lg`
    max-width: 95%;
  `}
`

const LeftQuoteIcon = styled(Icon)`
  position: absolute;
  left: ${(props) => -props.theme.spacing.md * 1.3}px;
  top: ${(props) => -props.theme.spacing.sm}px;

  ${media.sm`
    left: ${(props) => -props.theme.spacing.md * 1.7}px;
  `}
`

const RightQuoteIcon = styled(Icon)`
  position: absolute;
  right: ${(props) => -props.theme.spacing.md * 1.3}px;
  bottom: ${(props) => -props.theme.spacing.sm}px;

  ${media.sm`
    right: ${(props) => -props.theme.spacing.md * 1.7}px;
  `}
`

const RelativeView = styled(View)``

export const QuotesTicker = (props) => {
  const [visibleIndex, setVisibleIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(
      () =>
        setVisibleIndex((prev) => {
          const plusOne = prev + 1
          return plusOne === quotesTickerContent.length ? 0 : plusOne
        }),
      CHANGE_INTERVAL,
    )
    return () => clearInterval(interval)
  }, [])

  return (
    <Container {...props}>
      {quotesTickerContent.map((quote, i) => (
        <Transition key={i} in={visibleIndex === i} timeout={ANIMATION_DURATION}>
          {(state) => (
            <Wrapper>
              <Quote style={{ ...transitionStyles[state] }}>
                <span>
                  <RelativeView>
                    <LeftQuoteIcon name="quote-left" color="white" />
                  </RelativeView>
                </span>
                <i>{quote}</i>
                <span>
                  <RelativeView>
                    <RightQuoteIcon name="quote-right" color="white" />
                  </RelativeView>
                </span>
              </Quote>
            </Wrapper>
          )}
        </Transition>
      ))}
    </Container>
  )
}
