const createHref = (program) => `/entrepreneur-learning-mentoring-course/${program}Program`

export const programCardsContent = [
  // {
  //   bodyBackground: 'grayDark',
  //   bodyColor: 'white',
  //   bulletIconBackgroundColor: 'white',
  //   bullets: [
  //     'LIVE monthly Q&A Masterclasses',
  //     'LIVE monthly teaching',
  //     'Worldclass 1-2-1 mentoring',
  //     'Monthly video training ',
  //     'Monthly access to the Conversations library',
  //     'E-books and resources',
  //     '“Master” and “Expert” certificates',
  //   ],
  //   buttonBackground: 'primary',
  //   buttonTextColor: 'white',
  //   description:
  //     'Our most affordable and accessible membership program giving you everything you need to start and scale your business on a flexible monthly basis. Trial the program FREE for seven days.',
  //   dividerColor: 'white',
  //   href: createHref('subscription'),
  //   subtitle: 'Flexible Membership',
  //   subtitleBackground: 'primary',
  //   subtitleColor: 'white',
  //   tall: true,
  //   title: 'Success',
  //   titleBackground: 'white',
  //   titleColor: 'black',
  // },

  {
    bodyBackground: 'secondary',
    bodyColor: 'white',
    bulletIconBackgroundColor: 'white',
    bullets: [
      'Direct 1-2-1 mentoring support',
      'Instant access to a nine-module training program',
      'LIVE monthly Q&A Masterclasses',
      'Unrestricted access to the Conversations library',
      'E-books and resources',
      'Opportunity to Pitch your business idea to Martin Warner',
    ],
    buttonBackground: 'white',
    buttonTextColor: 'primary',
    description:
      'Our most popular program and ideal if you want to fast-track your success. Get instant access to everything we offer to help entrepreneurs.',
    dividerColor: 'black',
    href: createHref('accelerator'),
    subtitle: 'All-included Package',
    subtitleBackground: 'white',
    subtitleColor: 'black',
    tall: true,
    title: 'Idea to Exit Masterclass',
    titleBackground: 'black',
    titleColor: 'white',
    titleTopPadding: 'none',
  },
  {
    bodyBackground: 'white',
    bodyColor: 'black',
    bulletIconBackgroundColor: 'green',
    bullets: ['Nine-module training program', 'Audio and study guides', 'Success checklists'],
    buttonBackground: 'primary',
    buttonTextColor: 'white',
    description: 'For those who want to deepen their knowledge of business and entrepreneurship without mentoring.',
    dividerColor: 'black',
    href: createHref('foundation'),
    subtitle: '9 Video Trainings',
    subtitleBackground: 'black',
    subtitleColor: 'white',
    tall: true,
    title: 'Foundation',
    titleBackground: 'primary',
    titleColor: 'white',
  },
  {
    bodyBackground: 'white',
    bodyColor: 'black',
    bulletIconBackgroundColor: 'green',
    bullets: [
      'Learn from case studies and video teachings for successful fundraising strategies',
      'Get instant access to a comprehensive training program',
      'Join a live Q&A Masterclass with Martin',
      'Achieve your goals with the help of a written study guide and success checklist',
      "Get access to winning templates (Business Valuation, Pitch Deck, Cold Outreach Emails, Must-have Fundraising Checklist, Email Outreach Tracker, Do's and Don't of Fundraising)",
      "Martin's Directory containing Thousands of Investors",
    ],
    buttonBackground: 'primary',
    buttonTextColor: 'white',
    description:
      'This program is designed for entrepreneurs who are looking to raise funds for their business with guidance from a leading entrepreneur.',
    dividerColor: 'black',
    href: createHref('raisingFundsForBusiness'),
    subtitle: 'New Program',
    subtitleBackground: 'yellow',
    subtitleColor: 'primary',
    tall: true,
    title: 'Raising Funds for Business',
    titleBackground: 'primary',
    titleColor: 'white',
    titleTopPadding: 'none',
  },
  {
    bodyBackground: 'white',
    bodyColor: 'black',
    bulletIconBackgroundColor: 'green',
    bullets: [
      '1 LIVE Q&A Masterclass',
      'World-Class 1-2-1 mentoring for 90 days',
      'Identifying Opportunities in Entrepreneurship training',
      'E-books and resources',
      'Two Conversations Video Teachings From Martin',
    ],
    buttonBackground: 'primary',
    buttonTextColor: 'white',
    description: 'Perfect if you want to get a taste of our teaching and mentoring for a small one-time fee.',
    dividerColor: 'black',
    href: createHref('starter'),
    subtitle: '90 Days Introduction',
    subtitleBackground: 'black',
    subtitleColor: 'white',
    tall: true,
    title: 'Starter',
    titleBackground: 'primary',
    titleColor: 'white',
  },
]
