import React from 'react'
import styled from 'styled-components'
import { View } from '@components/layout'
import StaticImage from '../images/StaticImg'
import SlickSlider from '../layout/SlickSlider/SlickSlider'
import featuredInContent from './featuredInContent'

const LogoWrapper = styled(View)`
  width: 100%;
  justify-content: center;
  align-items: center;
`

const CompanyLogo = styled(StaticImage)`
  width: 150px;
`

const Slider = styled(SlickSlider)`
  max-width: 100vw;
`

const Slide = styled(View)`
  display: flex !important;
  flex-direction: row !important;
`

const VerticalDivider = styled(View)`
  width: 1px;
  background: ${(props) => props.theme.colors.grayLight};
  margin: ${(props) => props.theme.spacing.md}px 0;
`

const featuredSliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 1500,
  interval: 1500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
        arrows: false,
      },
    },
  ],
}

const FeaturedInSlider = (props) => (
  <Slider {...featuredSliderSettings} {...props}>
    {featuredInContent.map((image) => (
      <Slide key={image.name}>
        <LogoWrapper>
          <CompanyLogo src={`/about/featured-in/${image.name}`} />
        </LogoWrapper>
        <VerticalDivider />
      </Slide>
    ))}
  </Slider>
)

export default FeaturedInSlider
