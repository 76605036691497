import { useEffect, useState } from 'react'
import { LeadCaptureModal } from '@components/form/LeadCaptureModal'
import { SuccessModal } from './SuccessModal'

export const homeModals = {
  report: 'report',
  masterclass: 'masterclass',
  prospectus: 'prospectus',
  success: 'success',
}

const downloadTitles = {
  report: "'Life After the Pandemic: Opportunities in Entrepreneurship Report'",
  masterclass: "'Masterclass: 8 Must-Have Secrets for Business Success'",
  prospectus: "'Entrepreneur Program Prospectus'",
}

export const HomeModals = ({ visibleModal, showSuccessModal, hideAllModals }) => {
  const [downloadTitle, setDownloadTitle] = useState('')

  useEffect(() => {
    if (visibleModal && visibleModal !== homeModals.success) {
      setDownloadTitle(downloadTitles[visibleModal])
    }
  }, [visibleModal])

  return (
    <>
      <LeadCaptureModal
        onSubmitSuccess={showSuccessModal}
        includePhone
        noImageBorderRadius
        source="white-paper"
        visible={visibleModal === homeModals.report}
        onClose={hideAllModals}
        topText="Learn How You Can Build A Thriving Business"
        webImgSrc="home/pdf-cover-white-paper.png"
      />

      <LeadCaptureModal
        onSubmitSuccess={showSuccessModal}
        includePhone
        noImageBorderRadius
        source="masterclass"
        visible={visibleModal === homeModals.masterclass}
        onClose={hideAllModals}
        topText="Discover The Eight Must-Have Secrets For Business Success"
        webImgSrc="home/pdf-cover-masterclass.png"
      />

      <LeadCaptureModal
        onSubmitSuccess={showSuccessModal}
        includePhone
        noImageBorderRadius
        source="program-prospectus"
        visible={visibleModal === homeModals.prospectus}
        onClose={hideAllModals}
        topText="Download the Entrepreneur Seminar Prospectus"
        webImgSrc="home/prospectus_front_cover.png"
      />

      <SuccessModal
        downloadTitle={downloadTitle}
        visible={visibleModal === homeModals.success}
        onClose={hideAllModals}
      />
    </>
  )
}
