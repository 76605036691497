import styled from 'styled-components'
import { WebImage } from '@components/images/WebImage'
import { PageContent, View } from '@components/layout'
import { DefaultStyles, H2 } from '@components/typography'
import { media } from 'styled-bootstrap-grid'
import { shadows } from 'theme/styles'
import { whatMakesUsDifferentContent } from './content'

const Container = styled(PageContent)`
  align-items: center;
  ${media.md`
    align-items: stretch;
  `}
`

const Heading = styled(H2)`
  text-align: center;
  font-weight: bold;
`

const ItemsWrapper = styled(View)`
  ${media.md`
    flex-direction: row;
  `}
`

const Item = styled(DefaultStyles)`
  flex: 1;
  padding: ${(props) => props.theme.spacing.md}px;
  max-width: 400px;

  h3 {
    font-weight: bold;
  }
`

const ImageWrapper = styled(View)`
  margin-bottom: ${(props) => props.theme.spacing.md}px;
  overflow: hidden;
  border-radius: 20px;
  ${shadows.subtle}
`

export const WhatMakesUsDifferent = () => (
  <Container>
    <Heading>What Makes Us Different</Heading>
    <ItemsWrapper>
      {whatMakesUsDifferentContent.map((item) => (
        <Item key={item.imageSrc}>
          <ImageWrapper>
            <WebImage src={item.imageSrc} />
          </ImageWrapper>
          {item.renderTitle()}
          {item.renderDescription()}
        </Item>
      ))}
    </ItemsWrapper>
  </Container>
)
