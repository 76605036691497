import styled from 'styled-components'
import { BackgroundPattern } from '@components/images'
import { View } from '@components/layout'
import SlickSlider from '@components/layout/SlickSlider/SlickSlider'
import { StatItem } from './StatItem'
import { statsContent } from './content'

const FIRST_BREAK = 550

const sliderSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  responsive: [
    {
      // breakpoint means 'everything up to..'
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '25%',
      },
    },
    {
      breakpoint: FIRST_BREAK,
      settings: {
        centerPadding: '10%',
        slidesToShow: 1,
      },
    },
  ],
}

const Container = styled(View)`
  position: relative;
  margin-bottom: 100px;
`

const Background = styled(BackgroundPattern)`
  transform: translateY(50px);
  background-color: ${(props) => props.theme.colors.primary};
`

const Slider = styled(SlickSlider)`
  /* background-color: ${(props) => props.theme.colors.white}; */
  ul.slick-dots {
    bottom: -35px;

    button::before {
      height: 15px;
      width: 15px;
      border: 1px solid black;
      border-radius: 50%;
      color: ${(props) => props.theme.colors.offWhite};
      background: ${(props) => props.theme.colors.offWhite};
      background-color: ${(props) => props.theme.colors.offWhite};
      z-index: 1;
      overflow: hidden;
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }

    .slick-active {
      button::before {
        color: ${(props) => props.theme.colors.secondary};
        background: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`

const Slide = styled(View)`
  height: 100%;
`

const StyledStatItem = styled(StatItem)`
  height: 190px;

  @media (min-width: ${FIRST_BREAK}px) {
    height: 230px;
  }

  @media (min-width: 650px) {
    height: 200px;
  }

  box-shadow: none !important;
`

export const StatsSlider = () => {
  return (
    <Container>
      <Background patternOpacity={0.5} />
      <Slider interval={5000} {...sliderSettings}>
        {statsContent.map((props) => (
          <Slide key={props.text}>
            <StyledStatItem {...props} />
          </Slide>
        ))}
      </Slider>
    </Container>
  )
}
