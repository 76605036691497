import { homeModals } from '../Modals/HomeModals'

export const freeDownloadsContent = [
  {
    renderTitle: () => <p>Life After the Pandemic: Opportunities in Entrepreneurship Report</p>,
    imageSrc: 'home/pdf-cover-white-paper.png',
    buttonText: 'Free Download',
    modalName: homeModals.report,
  },
  {
    renderTitle: () => <p>Masterclass: 8 Must-Have Secrets for Business Success</p>,
    imageSrc: 'home/pdf-cover-masterclass.png',
    buttonText: 'Get Free Access',
    modalName: homeModals.masterclass,
  },
  {
    renderTitle: () => <p>Entrepreneur Program Prospectus</p>,
    imageSrc: 'home/prospectus_front_cover.png',
    buttonText: 'Free Download',
    modalName: homeModals.prospectus,
  },
]
