import styled from 'styled-components'
import { BackgroundPattern } from '@components/images'
import { WebImage } from '@components/images/WebImage'
import { CourseButton } from '@components/input/CourseButton'
import { PageContent, View } from '@components/layout'
import { H2, H3, P } from '@components/typography'
import { IconListItem } from '@components/typography/IconListItem'
import { RAISING_FUNDS_FOR_BUSINESS } from '@containers/PurchaseApi/PurchaseContainer'
import { media } from 'styled-bootstrap-grid'
import { homeWidths } from '../constants'

// import Link from 'next/link' RESTORE FOR SUCCESS PROGRAM STYLING
// import PrimaryCTA from '@components/input/Button' RESTORE FOR SUCCESS PROGRAM STYLING

const Container = styled(PageContent)`
  align-items: center;
  ${media.lg`
    margin-top: ${(props) => props.theme.spacing.xxxlg}px;
  `}
`

const Background = styled(BackgroundPattern)`
  background-color: ${(props) => props.theme.colors.secondary};
  max-height: 800px;
  ${media.lg`
    max-height: 740px;
    flex-direction: row;
  `}
`

const Content = styled(View)`
  justify-content: center;
  max-width: ${homeWidths.lg}px;
  ${media.lg`
    flex-direction: row;
  `}
`

const TextContent = styled(View)`
  padding: ${(props) => props.theme.spacing.md}px;
  margin-right: ${(props) => props.theme.spacing.md}px;
  margin-bottom: ${(props) => props.theme.spacing.xlg}px;
  ${media.lg`
    max-width: 600px;
  `}
`

const Heading = styled(H2)`
  color: ${(props) => props.theme.colors.white};
  margin-bottom: ${(props) => props.theme.spacing.md}px;
  font-weight: bold;
  ${media.xl`
    font-size: 30px;
  `}
`

const DescriptionWrapper = styled(View)``

const Description = styled(P)`
  color: ${(props) => props.theme.colors.white};
`

const CardAndMessageWrapper = styled(View)`
  padding: 0 ${(props) => props.theme.spacing.md}px;
`

const ProgramCard = styled(View)`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray};
  align-items: center;
  text-align: center;
  padding: ${(props) => props.theme.spacing.xlg}px ${(props) => props.theme.spacing.md}px;
  border-radius: 20px;
  margin-bottom: ${(props) => props.theme.spacing.md}px;
  align-self: center;
  ${media.md`
    width: 500px;
  `}
  ${media.lg`
    margin-top: ${(props) => -props.theme.spacing.lg * 3}px;
  `}
  ${media.xl`
    padding: ${(props) => props.theme.spacing.xxlg * 1.1}px ${(props) => props.theme.spacing.md}px;
  `}
`

const CardTitle = styled(H3)`
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
`
// RESTORE FOR SUCCESS PROGRAM STYLING
// const CardSubtitle = styled(H3)`
//   font-weight: bold;
//   margin-bottom: ${(props) => props.theme.spacing.md}px;
// `

const CardImage = styled(WebImage)`
  margin-bottom: ${(props) => props.theme.spacing.md}px;
  max-width: 200px;
  ${media.sm`
    max-width: 300px;
  `}
  ${media.lg`
    max-width: 350px;
  `}
`
// RESTORE FOR SUCCESS PROGRAM STYLING
// const NoCommitment = styled(P)`
//   text-align: center;
//   font-weight: bold;
//
//   ${media.lg`
//     color: ${(props) => props.theme.colors.white};
//   `}
// `

export const GetStarter = () => {
  return (
    <Container fluid>
      <Background patternOpacity={0.5} patternScale={2} />
      <Content>
        <TextContent>
          <Heading>
            Raise Funds Like a Pro by Following A Proven Step-By-Step Solution For Securing Funds for Your Business.
          </Heading>
          <DescriptionWrapper>
            <Description>
              When you join the program today you will unlock instant access to all of the below:
              <br />
            </Description>
            <Bullet>Learn from case studies and video teachings for successful fundraising strategies.</Bullet>
            <Bullet>Get instant access to a comprehensive training program.</Bullet>
            <Bullet>Join a live Q&A Masterclass with Martin.</Bullet>
            <Bullet>Achieve your goals with the help of a written study guide and success checklist.</Bullet>
            <Bullet>
              Get access to winning templates (Business Valuation, Pitch Deck, Cold Outreach Emails, Must-have
              Fundraising Checklist, Email Outreach Tracker, Do's and Don't of Fundraising).
            </Bullet>
            <Bullet>Martin's Directory containing Thousands of Investors.</Bullet>
            <Description>Unlock the potential of your business and click "Get Access Now" to start!</Description>
          </DescriptionWrapper>
        </TextContent>
        <CardAndMessageWrapper>
          <ProgramCard>
            <CardTitle>Raising Funds for Business Program</CardTitle>
            <CardImage src="programs/raising-funding-devices.png" />
            <CourseButton slug={RAISING_FUNDS_FOR_BUSINESS} />
          </ProgramCard>
        </CardAndMessageWrapper>
      </Content>
    </Container>
  )
}

const Bullet = (props) => <IconListItem {...props} textColor="white" />

// =======================================================================
// RESTORE THIS COMPONENT BELOW SHOULD THE SUCCESS PROGRAM BE RE-INSTATED.
// Also change the file name to the name of the component.
//========================================================================

// export const FreeTrial = () => {
//   return (
//     <Container fluid>
//       <Background patternOpacity={0.5} patternScale={2} />
//       <Content>
//         <TextContent>
//           <Heading>
//             The Ultimate Toolkit To Help You Thrive In Entrepreneurship - Try FREE For Seven Days
//           </Heading>
//           <DescriptionWrapper>
//             <Description>
//               Experience the full power of Entrepreneur Seminar’s teaching and mentoring with a free
//               seven-day trial of the Entrepreneur Success Program. You will get seven full days to
//               trial our accelerated learning program, direct 1-2-1 mentoring, and membership portal
//               before committing to the program.
//               <br />
//               <br />
//               You’ll get immediate access to:
//             </Description>
//             <Bullet>
//               The first accelerated learning module: Identifying Opportunities In Entrepreneurship
//             </Bullet>
//             <Bullet>
//               Direct 1-2-1 mentoring where you can immediately start asking Martin questions
//             </Bullet>
//             <Bullet>
//               Success Program member’s area where you can download resources to help you build and
//               grow your business
//             </Bullet>
//             <Bullet>
//               Video interviews with Martin that unpick the subtle details of entrepreneurship and
//               success
//             </Bullet>
//           </DescriptionWrapper>
//         </TextContent>
//         <CardAndMessageWrapper>
//           <ProgramCard>
//             <CardTitle>Success Program</CardTitle>
//             <CardSubtitle>7 Days Free Trial</CardSubtitle>
//             <CardImage src="programs/starter-devices.png" />
//             <Link href="http://edu.entrepreneurseminar.com/seven-day-free-trial" target="_blank">
//               <PrimaryCTA>Get Instant Access</PrimaryCTA>
//             </Link>
//           </ProgramCard>
//           <NoCommitment>No commitment. Cancel anytime.</NoCommitment>
//         </CardAndMessageWrapper>
//       </Content>
//     </Container>
//   )
// }