import { media } from 'styled-bootstrap-grid'
import { homeWidths } from '../constants'

const { View } = require('@components/layout')
const { default: styled } = require('styled-components')

export const CenteredContent = styled(View)`
  width: 100%;
  max-width: ${homeWidths.lg}px;
  ${media.lg`
    flex-direction: row;
  `}
`
