import styled from 'styled-components'
import { WebImage } from '@components/images/WebImage'
import { PageContent, View } from '@components/layout'
import { H2, P } from '@components/typography'
import { media } from 'styled-bootstrap-grid'

const Container = styled(PageContent)`
  padding-left: 0;
  padding-right: 0;
  /* border: 1px solid red; */

  ${media.lg`
    flex-direction: row;
  `}
`

const ImageWrapper = styled(View)`
  /* border: 1px solid blue; */
  ${media.lg`
    flex: 1;
    justify-content: center;
    width: 80%;
    flex-direction: row;
    padding-left: 0;
  `}
`

const MobileImage = styled(WebImage)`
  width: 100%;
  ${media.lg`
    display: none;
  `}
`

const DesktopImage = styled(WebImage)`
  display: none;
  object-fit: cover;
  ${media.lg`
    display: block;
  `}
`

const TextWrapper = styled(View)`
  flex: 1;
  /* border: 1px solid green; */
  padding: ${(props) => props.theme.spacing.lg}px;
  padding-bottom: 0;
  justify-content: center;

  ${media.xl`
    flex: 0.7;
  `}
`

const TextContent = styled(View)`
  align-items: flex-start;
  max-width: 900px;
`

const Heading = styled(H2)`
  font-weight: bold;
`

export const WhoWeveHelped = () => {
  return (
    <Container fluid>
      <ImageWrapper>
        <MobileImage src="home/entrepreneurs-grid-flat.jpg" />
        <DesktopImage src="home/entrepreneurs-grid-skewed.jpg" />
      </ImageWrapper>
      <TextWrapper>
        <TextContent>
          <Heading>We’ve Helped Over 300,000 Entrepreneurs And Counting</Heading>
          <P>
            The road to success in entrepreneurship throws up <b>unique challenges</b> not found in <b>any</b> other
            career path. You’re forced to wear many hats at once. Create and implement at speed, and challenge the
            status quo to find solutions to grow your business.
            <br />
            <br />
            Entrepreneur Seminar <b>helps you</b> navigate your journey in entrepreneurship. Whether you want to start a
            new business or scale your existing one.
            <br />
            <br />
            Through a combination of <b>award-winning education</b> programs and <b>direct mentoring</b> from leading
            entrepreneur Martin Warner, we’ve helped over 300,000 entrepreneurs start and grow their businesses. We{' '}
            <b>know</b> what it takes to build a successful business.
            <br />
            <br />
            Now we want to help <b>you</b> do the same.
          </P>
        </TextContent>
      </TextWrapper>
    </Container>
  )
}
