import styled from 'styled-components'
import { View } from '@components/layout'
import { H2 } from '@components/typography'
import { media } from 'styled-bootstrap-grid'
import { StatsGrid } from './StatsGrid'
import { StatsSlider } from './StatsSlider'

const Heading = styled(H2)`
  padding: ${(props) => props.theme.spacing.md}px;
  padding-bottom: 0;
  font-weight: bold;

  ${media.lg`
    text-align: center;
  `}
`

const SmallScreenOnly = styled(View)`
  ${media.md`
    display: none;
  `}
`

const LargeScreenOnly = styled(View)`
  display: none;

  ${media.md`
    display: flex;
  `}
`

export const Stats = () => {
  return (
    <>
      <Heading>The Facts At A Glance About Entrepreneurship And Mentoring</Heading>

      <SmallScreenOnly>
        <StatsSlider />
      </SmallScreenOnly>

      <LargeScreenOnly>
        <StatsGrid />
      </LargeScreenOnly>
    </>
  )
}
