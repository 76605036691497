import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components'
import { Icon } from '@components/images/Icon'
import { View } from '@components/layout'
import { H3, P } from '@components/typography'

const borderRadius = 20

const useMuiStyles = makeStyles(() => ({ paper: { borderRadius } }))

const Content = styled(View)`
  background: ${(props) => props.theme.colors.white};
  border-radius: ${borderRadius}px;
  overflow: hidden;
  max-width: 400px;
  flex-shrink: 0;
  padding: ${(props) => props.theme.spacing.md}px;
`

const CloseIconWrapper = styled(View)`
  cursor: pointer;
  align-self: stretch;
  align-items: flex-end;
  z-index: 1;
`

const TextWrapper = styled(View)`
  padding: ${(props) => props.theme.spacing.md}px;
`

const Heading = styled(H3)`
  font-weight: bold;
`

const Description = styled(P)``

export const SuccessModal = ({ onClose, visible, downloadTitle }) => {
  const classes = useMuiStyles()

  return (
    <Dialog classes={classes} open={visible} onClose={onClose}>
      <Content>
        <CloseIconWrapper>
          <Icon onClick={onClose} name="times" color="black" />
        </CloseIconWrapper>
        <TextWrapper>
          <Heading>Success!</Heading>
          <Description>
            We've just sent you a link to {downloadTitle}. You'll find it in your email inbox.
          </Description>
        </TextWrapper>
      </Content>
    </Dialog>
  )
}
