const featuredInContent = [
  {
    name: 'bbc-world-news.png',
  },
  {
    name: 'cbs-news.png',
  },
  {
    name: 'daily-express.png',
  },
  {
    name: 'fox.png',
  },
  {
    name: 'ft.png',
  },
  {
    name: 'huffpost.png',
  },
  {
    name: 'independent.png',
  },
  {
    name: 'sky-news.png',
  },
  {
    name: 'tech-crunch.png',
  },
  {
    name: 'the-guardian.png',
  },
  {
    name: 'the-times.png',
  },
  {
    name: 'variety.png',
  },
]

export default featuredInContent
