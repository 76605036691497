import styled from 'styled-components'
import { View } from '@components/layout'
import { H2, P } from '@components/typography'
import { media } from 'styled-bootstrap-grid'
import { shadows } from 'theme/styles'

const Stat = styled(View)`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grayLight};
  border-radius: 20px;
  flex: 1;
  margin: ${(props) => props.theme.spacing.sm}px;
  padding: ${(props) => props.theme.spacing.md}px ${(props) => props.theme.spacing.lg}px;
  max-width: 400px;
  ${shadows.subtle};

  ${media.lg`
  padding-bottom: 0;

  `}
`

const StatPercentage = styled(H2)`
  margin: 0;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 900;
  font-size: ${(props) => props.theme.fontSizes.xxxlg}px;
`

const StatText = styled(P)`
  color: ${(props) => props.theme.colors.paragraph};
  font-size: ${(props) => props.theme.fontSizes.md}px;
  font-weight: bold;
`

export const StatItem = ({ text, percent, ...props }) => (
  <Stat key={text.slice(0, 20)} {...props}>
    <StatPercentage>{percent}%</StatPercentage>
    <StatText>{text}</StatText>
  </Stat>
)
