import styled from 'styled-components'
import FeaturedInSlider from '@components/featuredInSlider/FeaturedInSlider'
import { webImageUrl } from '@components/images'
import PrimaryCTA from '@components/input/Button'
import { View } from '@components/layout'
import { H2, H4 } from '@components/typography'
import { WistiaPlayer } from '@components/video'
import { media } from 'styled-bootstrap-grid'
import { absoluteFill } from 'theme/styles'
import { QuotesTicker } from '../../components'
import { homeWidths } from '../../constants'

const Container = styled(View)`
  position: relative;
  min-height: ${(props) => `calc(100vh - ${props.theme.componentHeights.headerMobile}px)`};
  background-image: url(${webImageUrl('home/home-banner-mob.jpg')});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  align-items: center;

  ${media.md`
    min-height: ${(props) => `calc(100vh - ${props.theme.componentHeights.headerMobileMd}px)`};
  `}

  ${media.lg`
    background-image: url(${webImageUrl('home/home-banner-desktop.jpg')});
  `}

  ${media.xl`
    min-height: ${(props) => `calc(100vh - ${props.theme.componentHeights.headerDesktop}px)`};
  `}
`

const DarkOverlay = styled(View)`
  ${absoluteFill};
  background: black;
  opacity: 0.6;
`

const TopContent = styled(View)`
  flex: 1;
  width: 100%;
  z-index: 1;

  ${media.xl`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`

const MobileVideoWrapper = styled(View)`
  width: 100%;

  ${media.xl`
    display: none;
  `}
`

const DesktopVideoWrapper = styled(View)`
  display: none;

  ${media.xl`
    display: flex;
    width: 50%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    // fixes "overflow: hidden" not working on Safari
    transform: translateZ(0);
  `}

  @media(min-width: 1520px) {
    width: 60%;
  }
`

const TextContentAndCTAWrapper = styled(View)`
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  max-width: 600px;
  align-self: center;

  ${media.xl`
    max-width: unset;
  `}
`

const TextContentAndCTA = styled(View)`
  max-width: ${homeWidths.lg}px;
  padding: ${(props) => props.theme.spacing.md}px;

  ${media.xl`
    align-items: flex-start;
    max-width: 650px;
    padding: ${(props) => props.theme.spacing.lg}px;
  `}

  @media(min-width: 1520px) {
    max-width: 850px;
  }
`

const Heading = styled(H2)`
  margin-block-start: 0;
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;

  ${media.md`
    text-align: center;
  `}

  ${media.xl`
    text-align: left;
  `}
`

const Description = styled(H4)`
  color: ${(props) => props.theme.colors.white};
  opacity: 0.9;

  ${media.md`
    text-align: center;
  `}

  ${media.xl`
    text-align: left;
  `}
`

const PressSlider = styled(FeaturedInSlider)`
  background-color: ${(props) => props.theme.colors.offWhite};
`

const BannerVideo = ({ device, ...props }) => (
  <WistiaPlayer
    {...props}
    playerId={`home-banner-video-${device}`}
    url="https://entrepreneurseminar-es.wistia.com/medias/knf7vcifff"
    playbar={false}
  />
)

const MainButton = styled(PrimaryCTA)`
  font-size: ${(props) => props.theme.fontSizes.sm}px;

  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.md}px;
  `}
`

const PressSliderWrapper = styled(View)`
  width: 100%;
  overflow: hidden;
`

export const MainBanner = ({ onClickButton }) => {
  return (
    <Container>
      <DarkOverlay />
      <TopContent>
        <MobileVideoWrapper>
          <BannerVideo device="mobile" />
        </MobileVideoWrapper>
        <TextContentAndCTAWrapper>
          <TextContentAndCTA>
            <Heading>We Help You Double Your Chances Of Success In Entrepreneurship</Heading>
            <Description>
              We believe that with the best education, mentoring, and support, everyone has the potential to run a great
              business.
            </Description>
            <MainButton onClick={onClickButton} autoAlign>
              Download Prospectus
            </MainButton>
          </TextContentAndCTA>
        </TextContentAndCTAWrapper>
        <DesktopVideoWrapper>
          <BannerVideo device="desktop" />
        </DesktopVideoWrapper>
      </TopContent>
      <QuotesTicker />
      <PressSliderWrapper>
        <PressSlider slidesToShow={5} slidesToScroll={3} />
      </PressSliderWrapper>
    </Container>
  )
}
