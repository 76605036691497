import styled from 'styled-components'
import { Icon } from '@components/images'
import { View } from '@components/layout'
import P from './P/P'

const BulletWrapper = styled(View)`
  flex-direction: row;
`

const BulletText = styled(P)`
  color: ${(props) => props.theme.colors[props.textColor]};
`

const BulletPoint = styled(Icon)`
  background: ${(props) => props.theme.colors[props.backgroundColor]};
  align-self: flex-start;
  margin-right: ${(props) => props.theme.spacing.sm}px;
  padding: ${(props) => props.theme.spacing.xs}px;
  border-radius: 50%;
  color: ${(props) => props.color};
  border: ${(props) => (props.border ? `2px solid ${props.border}` : null)};
`

export const IconListItem = ({
  iconName = 'tick',
  iconColor,
  iconBackgroundColor = 'white',
  iconSize = 10,
  textColor = 'paragraph',
  children,
  border,
}) => (
  <BulletWrapper>
    <BulletPoint
      backgroundColor={iconBackgroundColor}
      name={iconName}
      color={iconColor}
      size={iconSize}
      border={border}
    />
    <BulletText textColor={textColor}>{children}</BulletText>
  </BulletWrapper>
)
