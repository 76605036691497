import styled from 'styled-components'
import { WebImage } from '@components/images'
import PrimaryCTA from '@components/input/Button'
import { PageContent, View } from '@components/layout'
import { H3 } from '@components/typography'
import { media } from 'styled-bootstrap-grid'
import { shadows } from 'theme/styles'
import { homeWidths } from '../../constants'
import { freeDownloadsContent } from './content'

const Container = styled(PageContent)`
  max-width: ${homeWidths.lg}px;
  align-items: center;

  ${media.lg`
    align-items: stretch; 
    padding: ${(props) => props.theme.spacing.lg}px;
  `}
`

const CardsWrapper = styled(View)`
  ${media.lg`
    flex-direction: row;
    justify-content: center;
  `}
`

const Card = styled(View)`
  ${shadows.subtle}
  align-items: center;
  padding: ${(props) => props.theme.spacing.md}px;
  border: 4px solid ${(props) => props.theme.colors.primary};
  border-radius: 20px;
  flex: 1;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing.md}px;
  max-width: 350px;

  ${media.md`
    margin: ${(props) => props.theme.spacing.md}px;
    min-width: 400px;
  `}

  ${media.lg`
    min-width: unset;
  `}
`

const CardTitle = styled(H3)`
  text-align: center;
  line-height: ${(props) => props.theme.fontSizes.xlg}px;
  margin-bottom: ${(props) => props.theme.spacing.md}px;
  font-weight: bold;

  ${media.md`
    margin: ${(props) => props.theme.spacing.md}px;
  `}

  p {
    margin-bottom: 0;
  }
`

const Bottom = styled(View)`
  align-items: center;
`

const Image = styled(WebImage)`
  max-height: 230px;
  margin-bottom: ${(props) => props.theme.spacing.lg}px;
`

export const FreeDownloads = ({ setVisibleModal }) => (
  <Container>
    <CardsWrapper>
      {freeDownloadsContent.map((item) => (
        <Card key={item.modalName}>
          <CardTitle>{item.renderTitle()}</CardTitle>
          <Bottom>
            <Image src={item.imageSrc} />
            <PrimaryCTA onClick={() => setVisibleModal(item.modalName)}>
              {item.buttonText}
            </PrimaryCTA>
          </Bottom>
        </Card>
      ))}
    </CardsWrapper>
  </Container>
)
