export const whatMakesUsDifferentContent = [
  {
    imageSrc: 'home/different-1.jpg',
    renderTitle: () => <h3>1. Accelerated Training</h3>,
    renderDescription: () => (
      <p>
        Our accelerated training programs help you develop the <b>crucial skills</b> and{' '}
        <b>knowledge</b> you need to run your business, and fast. You will learn everything from
        market research and business formation to branding, marketing and business sale.
      </p>
    ),
  },

  {
    imageSrc: 'home/different-2.jpg',
    renderTitle: () => <h3>2. Practical, Actionable Teachings</h3>,
    renderDescription: () => (
      <p>
        Our programs don’t just teach. They help you apply your learnings to your business, too. As
        you move through the programs you will see <b>real world examples</b> of our teaching, and
        you can ask for support every step of the way.
      </p>
    ),
  },

  {
    imageSrc: 'home/different-3.jpg',
    renderTitle: () => <h3>3. World-Class Mentorship</h3>,
    renderDescription: () => (
      <p>
        We give you <b>24/7 unrivaled access</b> to <b>leading entrepreneur Martin Warner</b>. No
        other entrepreneurship programs give you the same amount of access as ours does. You can use
        Martin’s experience and knowledge to help you <b>progress your business</b>.
      </p>
    ),
  },
]
