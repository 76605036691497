export const statsContent = [
  {
    percent: 60,
    text: 'of businesses fail within the first five years.',
  },
  {
    percent: 85,
    text: 'of entrepreneurs fail for the same three reasons.',
  },
  {
    percent: 96,
    text:
      'of entrepreneurs or business owners don’t want to go back to working for another company.',
  },
  {
    percent: 100,
    text:
      'Research has shown that mentored businesses are 100% more likely to survive past the five-year mark.',
  },
  {
    percent: 92,
    text:
      'of small business owners say that mentoring had a direct impact on their survival and growth.',
  },
  {
    percent: 96,
    text: 'of successful start-ups agree that mentoring was critical to their success.',
  },
]
