import styled from 'styled-components'
import { BackgroundPattern } from '@components/images'
import { WebImage } from '@components/images/WebImage'
import PrimaryCTA from '@components/input/Button'
import { View } from '@components/layout'
import { H2, P } from '@components/typography'
import Link from 'next/link'
import { media } from 'styled-bootstrap-grid'
import { CenteredContent } from '../components'

const IMAGE_SRC = 'common/martin/with-plants-landscape.jpg'

const Container = styled(View)`
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing.md}px;

  @media (min-width: 1600px) {
    justify-content: center;
    padding: ${(props) => props.theme.spacing.md}px 0;
  }
`

const Content = styled(CenteredContent)`
  flex: 1;
  align-items: center;
  ${media.md`
    align-items: center;
    justify-content: flex-end;
  `}

  @media (min-width: 1600px) {
    justify-content: center;
  }
`

const SmallAndXxlScreenImage = styled(WebImage)`
  ${media.sm`
    max-width: 100%;
    margin: ${(props) => props.theme.spacing.md}px;
    margin-top: 0;
    margin-bottom: 0;
  `}
  ${media.lg`
    max-width: 400px;
    border-radius: 10px;
  `}

  ${media.xl`
    display: none;
  `}

  @media(min-width: 1600px) {
    display: block;
    max-width: 650px;
  }
`

const DesktopImageWrapper = styled(View)`
  display: none;
  flex: 1;
  align-items: flex-end;

  ${media.xl`
    display: flex;
    max-width: 740px;
    height: 100%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 20% 100%);
  `}

  @media(min-width: 1600px) {
    display: none;
  }
`

const DesktopImage = styled(WebImage)`
  max-width: 800px;
`

const Background = styled(BackgroundPattern)`
  background-color: ${(props) => props.theme.colors.primary};
`

const TextWrapper = styled(View)`
  flex: 1;
  padding: ${(props) => props.theme.spacing.md}px;
  align-items: stretch;

  ${media.md`
    max-width: 700px;
  `}

  ${media.xl`
    max-width: 600px;
    align-items: flex-start;
  `}
`

const Heading = styled(H2)`
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};
`

const Description = styled(P)`
  color: ${(props) => props.theme.colors.white};
`

const ReadMoreButton = styled(PrimaryCTA)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primary};

  :hover {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`

export const MoreAboutMartin = () => {
  return (
    <Container>
      <Background patternScale={2} />
      <Content>
        <SmallAndXxlScreenImage src={IMAGE_SRC} />

        <TextWrapper>
          <Heading>Discover More About Martin</Heading>
          <Description>
            One of Britain's leading entrepreneurs, renowned for having built and sold a business for{' '}
            <b>$50 million in a record-breaking 17 months</b>. Martin Warner is a global thought leader on
            entrepreneurship and a business mentor, educator, inventor, film producer, and investor. He has created
            several hundred million dollars of shareholder value and built several global businesses from scratch. He
            also holds over 120 patents.
            <br />
            <br />
            Having taught entrepreneurship for <b>20 years</b> and impacted over <b>300,000 people</b>, Martin now
            shares his thought leadership, teachings, and coaching through his education program, Entrepreneur Seminar.
          </Description>
          <Link href="/about-martin-warner">
            <ReadMoreButton autoAlign>Read More</ReadMoreButton>
          </Link>
        </TextWrapper>
      </Content>
      <DesktopImageWrapper>
        <DesktopImage src={IMAGE_SRC} />
      </DesktopImageWrapper>
    </Container>
  )
}
