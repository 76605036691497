import styled from 'styled-components'
import { BackgroundPattern } from '@components/images'
import { PageContent, View } from '@components/layout'
import { H2, P } from '@components/typography'
import { chunk } from 'lodash'
import { media } from 'styled-bootstrap-grid'
import { homeWidths } from '../../constants'
import { ProgramCard } from './ProgramCard'
import { programCardsContent } from './content'

const Background = styled(BackgroundPattern)`
  background-color: ${(props) => props.theme.colors.grayLighter};
`

const Content = styled(View)`
  max-width: ${homeWidths.lg}px;
  align-self: center;
`

const Heading = styled(H2)`
  text-align: center;
  font-weight: bold;
`

const Description = styled(P)`
  text-align: center;
  padding: 0 ${(props) => props.theme.spacing.lg}px;
  margin: 0;
`

const ProgramCards = styled(View)`
  align-items: center;

  // Switch back to this media query if Success Program (i.e. a 4th card) is re-instated.
  //@media (min-width: 1380px) {
  //  align-items: flex-start;
  //  flex-direction: row;
  //}

  @media (min-width: 1080px) {
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
`

const CardRow = styled(View)`
  ${media.md`
    flex-direction: row;
  `}
`

export const Programs = () => {
  return (
    <PageContent fluid mb="lg">
      <Background grayscalePercent={100} patternOpacity={0.05} />
      <Content>
        <Heading>Discover How Our Programs Can Help You</Heading>
        <Description>
          Whether you are just starting out in entrepreneurship or already have an existing business
          that you want to scale, we have a tailor-made solution to help you succeed. Explore our
          programs below to find your best match.
        </Description>
        <ProgramCards>
          {chunk(programCardsContent, 2).map((cardRow) => (
            <CardRow key={cardRow[0].title}>{cardRow.map(ProgramCard)}</CardRow>
          ))}
        </ProgramCards>
      </Content>
    </PageContent>
  )
}
